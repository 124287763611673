import BmiCalculator from "./BMICalculator";

function App() {
  return (
    <div className="container">
      <nav>
        <div className="logo">
          <img src="./AYURCENTRALLOGO1.png" alt="logo" />
        </div>
      </nav>
      <BmiCalculator />
    </div>
  );
}

export default App;
