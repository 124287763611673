import React, { useState } from "react";

const cmTOmeter = 100;
const inchTOfeet = 12;
const feetTOmeter = 3.281;
const feetArr = [3, 4, 5, 6, 7];
const InchArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const BmiCalculator = () => {
  const [weight, setWeight] = useState("");
  const [unit, setUnit] = useState("cm");
  const [feetOption, setFeetOption] = useState(feetArr[0]);
  const [inchOption, setInchOption] = useState(InchArr[0]);

  const [height, setHeight] = useState("");

  const [bmi, setBmi] = useState(null);

  const handleWeightChange = (e) => {
    setWeight(parseFloat(e.target.value));
  };

  const handleHeightChange = (e) => {
    setHeight(parseFloat(e.target.value));
  };

  const handleUnitChange = (e) => {
    setHeight("");
    setBmi("");
    setUnit(e.target.value);
  };
  const handleFeetOptionChange = (e) => {
    setFeetOption(parseFloat(e.target.value));
  };
  const handleInchOptionChange = (e) => {
    setInchOption(parseFloat(e.target.value));
  };

  const calculateBMI = () => {
    const weightInKg = weight;

    const heightInM =
      unit === "cm"
        ? height / cmTOmeter
        : (inchOption / inchTOfeet + feetOption) / feetTOmeter;

    const bmi = weightInKg / (heightInM * heightInM);
    return setBmi(parseFloat(bmi).toFixed(1));
  };

  return (
    <div className="calculator">
      <h2>BMI Calculator</h2>
      <div className="bmi-calc-holder">
        <div className="bmi-calcu">
          <div className="bmi-unit bmi-heigth">
            <span className="unit">Height=</span>
            {unit === "cm" && (
              <div className="height-cm height-value">
                <div className="value-box">
                  <input
                    type="number"
                    className="unit-value"
                    value={height}
                    onChange={handleHeightChange}
                  />
                  <span>cm</span>
                </div>
              </div>
            )}
            {unit === "ft" && (
              <div className="height-ft height-value">
                <div className="value-box value-ft-box">
                  <select
                    value={feetOption}
                    onChange={handleFeetOptionChange}
                    className="unit-value"
                  >
                    {feetArr.map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span>ft</span>
                </div>
                <div className="value-box value-ft-box">
                  <select
                    value={inchOption}
                    onChange={handleInchOptionChange}
                    className="unit-value"
                  >
                    {InchArr.map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span>in</span>
                </div>
              </div>
            )}
            <select
              value={unit}
              onChange={handleUnitChange}
              className="unit-dropdown"
            >
              <option value="cm">cm</option>
              <option value="ft">feet</option>
            </select>
          </div>

          <div className="bmi-unit bmi-weight">
            <span className="unit">Weight=</span>
            <div className="value-box">
              <input
                type="number"
                className="unit-value"
                value={weight}
                onChange={handleWeightChange}
              />
              <span>kg</span>
            </div>
          </div>
        </div>
        <div className="bmi-calcu-btn">
          <button onClick={() => calculateBMI()}>Calculate BMI</button>
        </div>
        <div className="bmi-result">
          <div className="bmi-result-unit bmi-value">
            <span className="unit">BMI:</span>
            <span className="result-unit">{bmi || 0}</span>
          </div>
          <div className="bmi-result-unit bmi-value">
            <span className="unit">Result:</span>
            <span className="result-unit">
              {bmi < 18.5
                ? "Underweight"
                : bmi >= 18.5 && bmi < 24.9
                ? "Normal weight"
                : bmi >= 25.0 && bmi < 29.9
                ? "Overweight"
                : "Obese"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BmiCalculator;
